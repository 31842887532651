import '../styles/loader.css';

import React from 'react';

const Loader = (  props  ) => {
  console.log( 'props loader',props.noBackground );
  const noBack = props.noBackground ? 'transparent' : '';
  return (
    <div id="pause" className={`d-flex align-items-center justify-content-center ${noBack}`}>
      <div id="spinner"></div>
    </div>
  );
};

export default Loader;
