import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';

import Loader from '../components/loader';

function Login() {
  const { loginWithRedirect } = useAuth0();

  useEffect( () => {
    loginWithRedirect();
  }, [] );
  return(
    <>
      <Loader/>
    </>
  );
}

export default Login;
